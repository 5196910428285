import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RedirectComponent } from './redirect/redirect.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  {
    //https://creativakids-pre.proximateapps.net/santillana/autologin/lmls/6599164/7ba60012f84023d4533421579446f6ad3ef1e92983
    path:'santillana/autologin/lmls/:userId/:token',
    component : RedirectComponent
  }
];

@NgModule({
  // providers: [
  //   {
  //       provide: externalUrlProvider,
  //       useValue: (route: ActivatedRouteSnapshot) => {
  //           const externalUrl = route.paramMap.get('externalUrl');
  //           window.open(externalUrl, '_self');
  //       },
  //   },
  // ],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
