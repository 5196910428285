import { Component, OnInit } from '@angular/core';
import {DataService} from '../data.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {
  userId: any;
  token: any;
  serviceResponse$: any;
  cargando$: boolean = true;
  errorRedirectToLMS= "";
  public lottieConfig: Object;
  private anim: any;
  private animationSpeed: number = 1;

  constructor(private data: DataService, private routeGetParams: ActivatedRoute ) { 
    this.lottieConfig = {
        path: 'assets/spinner/mbot.json',
        autoplay: true,
        loop: true
    };
  }
  handleAnimation(anim: any) {
    this.anim = anim;
}

stop() {
    this.anim.stop();
}

play() {
    this.anim.play();
}

pause() {
    this.anim.pause();
}

setSpeed(speed: number) {
    this.animationSpeed = speed;
    this.anim.setSpeed(speed);
}
  // constructor(private data: DataService) { }
  ngOnInit() {
    console.log("********** Hola");
    this.userId = this.routeGetParams.snapshot.paramMap.get('userId');
    this.token = this.routeGetParams.snapshot.paramMap.get('token');
    //TO GET QUERY PARAMS : getPuntosRecarga?lat=19.354583200000004&lon=-99.1854098

    // this.routeGetParams.queryParams.subscribe(params => {
    //   this.userId = params['user']
    //   this.token = params['token']
    // });
    console.log("********** params: user: " + this.userId +" token: " + this.token);
    this.data.redirectToLMS(this.userId, this.token).subscribe(
      data => {
        this.serviceResponse$ = data;
        console.log(this.serviceResponse$)
        if(this.serviceResponse$.success){
          window.location.href = this.serviceResponse$.urlLMS
        }
        else{
          if (this.serviceResponse$.message != 'No se encontraron cursos' && this.serviceResponse$.urlLMS != '' ){
            window.location.href = this.serviceResponse$.urlLMS
          }else{
            this.cargando$ = false;
            console.log(this.serviceResponse$.message)
            this.errorRedirectToLMS = 'Error: ' + this.serviceResponse$.message
          }
          
        }
        
      }
    );
    
  }

}
