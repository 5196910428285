import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  data :Object
  constructor(private http: HttpClient) { }
  
  redirectToLMS(user, token){
    console.log("data.service redirectToLMS")
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'x-api-key': '9D60L37Eho6a0TocG0jHP8RJvTm0LQuQ3s5QEeH8'
      })
    };  

    var dataUser = {
      userId: user,
      token: token
    }
    // this.urlStr = 'https://serveless.proximateapps-services.com.mx/creativakids/dev/api/lms/autologin';
    return this.http.post('https://serveless.proximateapps-services.com.mx/creativakids/dev/api/lms/autologin', dataUser, httpOptions);
  }
}
